/* eslint-disable no-lone-blocks */
import React from 'react';
import axios from 'axios';
import { FaFileUpload } from 'react-icons/fa';

import '../styles/_FileUploadForm.scss';

const FileUploadForm = ({ step, nextStep }) => {

  const handleFileUpload= (event) => {
      console.log('handle file upload');
      const data = new FormData();
      data.append('file', event.target.files[0]);
      data.append('name', event.target.files[0].name);
      data.append('description', 'some value user types');

      const url = 'https://apilolo.com/api/upload';
      axios.post(url, data).then((response) => {
          console.log(response);
          nextStep();
      });
  }

  {
    if (step === 1) {
        console.log('step:', step);
        return (
             <div className="FileUploadForm">
              <FaFileUpload className="fileUploadIcon" />
              <span className="FileUploadForm-text">Upload file here</span>

              <form 
                className="UploadWizard_fileUploadForm" 
                method="post"
                action="#">
                
                 <input className="FileUploadForm-uploadBtn" type="file" name="upload" onChange={handleFileUpload}/>
                 <input type="submit" className="FileUploadForm-submitBtn"/>
              </form>
             </div>
        );
    } else {
     return null;
    }
  }
}

export default FileUploadForm;
