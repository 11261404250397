/* eslint-disable no-lone-blocks */
import React from 'react';

import '../styles/_SuccessWizard.scss';

const SuccessWizard = ({step, resetWizard}) => {
     {
        if (step === 2) {
            return (
                <div className="SuccessWizard">
                    <p>Thanks for sharing</p>
                    <div className="SuccessWizard-resetBtn" onClick={() => resetWizard()}>
                        upload another
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default SuccessWizard;