import React, { Component } from 'react';

import './styles/app.scss';

import UploadWizard from './components/UploadWizard';

class App extends Component {
  render() {
    return (
      <div className="loloAdmin">
        <UploadWizard />
      </div>
   );
  }
}

export default App;
