/* eslint-disable no-lone-blocks */
import React from 'react';

import '../styles/_WizardDisclosure.scss';

const WizardDisclosure = ({ step, nextStep, submitForm }) => {
    {
    if (step === 0) {
        return (
              <div className="WizardDisclosure">
                <h3>Upload Step 1</h3>

                <div className="WizardDisclosure-rules">
                  <h4>Rules</h4>
                  <p>I certify that all depicted are 18 years <br/> of age and consenting adults</p>
                  <p>I certify that I am the owner of the content uploaded</p>
                  <p>I certify that I have conest of all depicted in the content uploaded</p>
                </div>
                 
                 <div className="WizardDisclosure-actions">
                    <div onClick={() => nextStep()}>AGREE</div>
                    <div>DISAGREE</div>
                 </div>
              </div>
        );
    } else {
        return null;
    }
    }
}

export default WizardDisclosure;