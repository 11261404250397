import React, { Component } from 'react';

import WizardDisclosure from './WizardDisclosure';
import FileUploadForm from './FileUploadForm';
import SuccessWizard from './SuccessWizard';

import '../styles/_UploadWizard.scss';

export default class UploadWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
        step: 0
    }

    this.nextStep = this.nextStep.bind(this);
    this.resetWizard = this.resetWizard.bind(this);
  }

  nextStep() {
    const { step } = this.state;
    this.setState({
        step : step + 1
    });
  }

  resetWizard() {
      this.setState({ step: 1 })
  }

  render() {
      const { step } = this.state;

      return (
         <div className="UploadWizard">
            <WizardDisclosure step={step} nextStep={this.nextStep} />
            <FileUploadForm step={step} nextStep={this.nextStep} />
            <SuccessWizard step={step} resetWizard={this.resetWizard} />
         </div>
      );
  }
}